.wrapp_nav .bg {
  display: none; }

.wrapp_nav .nav {
  display: block !important;
  position: absolute;
  top: 0;
  right: 0; }

.wrapp {
  padding-top: 78.9px;
  margin-bottom: 137px; }
  .wrapp .container {
    position: relative; }
    .wrapp .container .headnig {
      position: absolute;
      left: 15px; }
      .wrapp .container .headnig h1 {
        font-weight: 700; }
        .wrapp .container .headnig h1::after {
          all: unset; }
    .wrapp .container .position {
      top: 22.1px;
      position: absolute;
      right: 15px;
      color: #DA231B; }
      .wrapp .container .position h3 {
        font-size: 20px;
        line-height: 24px; }
      .wrapp .container .position a {
        color: #1F1A16;
        text-decoration: none; }

@media screen and (max-width: 767px) and (min-width: 320px) {
  .wrapp .container .position {
    top: 13px; } }

.about_Section1 .LeftDots .slika {
  height: 350px;
  width: 100%;
  object-fit: contain; }

.about_Section1 h4 {
  color: #DA231B;
  font-weight: 600; }

.about_Section1 h1::after {
  margin-bottom: 26px; }

.about_Section1 p {
  padding-bottom: 30px; }

.about_Section1 .content {
  padding-bottom: 115px;
  padding-top: 25px; }
  .about_Section1 .content .LeftDots {
    position: relative;
    width: 350px; }
    .about_Section1 .content .LeftDots .dotsl {
      position: absolute;
      right: -45px;
      bottom: -40px;
      z-index: -1; }

.about_Section1 .RightDots {
  position: relative; }
  .about_Section1 .RightDots .dotsr {
    position: absolute;
    bottom: -40px;
    left: -45px;
    z-index: -1; }

@media screen and (max-width: 767px) and (min-width: 320px) {
  .about_Section1 .content {
    padding: 0; }
    .about_Section1 .content .LeftDots {
      width: auto; }
      .about_Section1 .content .LeftDots .dotsl {
        display: none; }
  .about_Section1 .RightDots .dotsr {
    display: none; } }

@media screen and (max-width: 1024px) and (min-width: 768px) {
  .wrapp_nav .ss .bg {
    display: none; }
  .wrapp_nav .ss .nav {
    display: none !important; } }

.bg {
  position: absolute;
  right: 0;
  top: 0;
  overflow-x: hidden !important; }

.nav {
  display: none !important; }

.activ {
  color: #DA231B !important; }

.wrapper {
  display: none; }

.down {
  background-color: white;
  position: fixed !important;
  width: 100%;
  z-index: 22;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); }

.aaa {
  position: relative;
  height: 150px; }

.navbar {
  height: 150px;
  top: 0; }
  .navbar .container {
    position: relative; }
    .navbar .container .wrapper {
      border-bottom: 1px solid #707070;
      display: flex; }
      .navbar .container .wrapper p {
        font-size: 20px;
        margin-left: 18px;
        margin-bottom: 0; }
      .navbar .container .wrapper .phone {
        display: flex;
        padding-bottom: 17px; }
      .navbar .container .wrapper .socialMedia {
        display: flex;
        position: absolute;
        right: 15px;
        border-bottom: 1px solid #707070;
        padding-bottom: 17px; }
        .navbar .container .wrapper .socialMedia svg {
          margin-left: 18px; }
    .navbar .container .navbar-nav .nav-item {
      margin-top: 11px;
      margin-right: 49px; }
      .navbar .container .navbar-nav .nav-item .nav-link {
        padding: 0 !important;
        color: #1F1A16;
        font-size: 20px;
        font-weight: 600;
        text-decoration: none; }

@media screen and (max-width: 767px) and (min-width: 320px) {
  .bg {
    display: none; }
  .wrapper {
    background-color: white;
    margin-top: 0;
    display: flex;
    padding-top: 5px;
    padding-bottom: 5px; }
    .wrapper .phone {
      display: flex;
      margin-left: 15px; }
      .wrapper .phone P {
        margin-left: 5px; }
    .wrapper .socialMedia {
      display: flex;
      margin-left: 30px; }
      .wrapper .socialMedia svg {
        margin-left: 5px; }
  .navbar {
    height: auto;
    position: unset;
    top: unset;
    position: fixed !important;
    width: 100%;
    margin: 0;
    z-index: 5;
    background-color: white;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); }
    .navbar .container {
      top: unset; }
      .navbar .container .navbar-brand .log {
        width: 220px !important; }
      .navbar .container .wrapper {
        display: none; }
      .navbar .container .navbar-nav .nav-item {
        margin-top: 11px;
        margin-right: 49px; }
        .navbar .container .navbar-nav .nav-item a {
          font-size: 18px; } }

@media screen and (max-width: 1024px) and (min-width: 768px) {
  .ss {
    position: relative;
    top: -25px; }
    .ss .bg {
      right: -209px;
      top: 93px; }
  .wrapper {
    display: none; }
  .navbar {
    height: auto;
    background-color: white;
    z-index: 5; }
    .navbar .container .wrapper {
      display: none; } }

@media screen and (max-width: 1366px) and (min-width: 1024px) {
  .ss {
    top: -25px;
    position: relative; }
    .ss .bg {
      right: -392px; }
  .navbar .container .navbar-nav .nav-item {
    margin-right: 20px; } }

.lightbox {
  overflow-y: hidden !important;
  overflow-x: hidden !important;
  background-color: #333333;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1213113212; }
  .lightbox .container {
    background: #333333; }
  .lightbox img {
    height: 100vh;
    object-fit: contain;
    margin-top: 40px; }
  .lightbox .fa-times {
    color: white;
    font-size: 42px !important;
    position: absolute;
    top: 60px;
    right: 50px;
    z-index: 2; }
    .lightbox .fa-times:hover {
      cursor: pointer; }
  .lightbox .fa-angle-left {
    color: white;
    font-size: 50px;
    position: fixed;
    top: 48%;
    left: 40px; }
    .lightbox .fa-angle-left:hover {
      cursor: pointer; }
  .lightbox .fa-angle-right {
    color: white;
    font-size: 50px;
    position: fixed;
    top: 48%;
    right: 40px; }
    .lightbox .fa-angle-right:hover {
      cursor: pointer; }

.pictures {
  position: relative; }
  .pictures img {
    display: block;
    margin: auto;
    position: relative;
    top: 35%; }

.overlay {
  position: absolute;
  top: 0;
  bottom: 0px;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: .5s ease;
  background-color: #d67a7a; }

.pictures:hover .overlay {
  opacity: 0.8; }
  .pictures:hover .overlay img {
    margin: auto;
    display: block;
    position: relative;
    top: 35%; }

.HowWeWork {
  margin-top: 50px;
  background-color: #F6F7F9; }
  .HowWeWork .content {
    padding-top: 70px; }
    .HowWeWork .content .image {
      padding-top: 20px; }
    .HowWeWork .content p {
      line-height: 34px; }
      .HowWeWork .content p:last-child {
        color: #ff0000; }
  .HowWeWork .companyinfo {
    padding-top: 170px;
    padding-bottom: 87px; }
    .HowWeWork .companyinfo svg {
      float: left; }
    .HowWeWork .companyinfo .info {
      position: relative;
      left: 30px; }
    .HowWeWork .companyinfo h2 {
      margin: 0;
      font-size: 60px;
      line-height: 72px;
      font-weight: 700; }

@media screen and (max-width: 767px) and (min-width: 320px) {
  .HowWeWork .content h1 {
    padding-top: 20px; }
  .HowWeWork .content p {
    line-height: 28px; }
  .HowWeWork .companyinfo {
    padding-bottom: 40px;
    padding-top: 70px; }
    .HowWeWork .companyinfo svg {
      float: unset; }
    .HowWeWork .companyinfo .info {
      left: unset; }
      .HowWeWork .companyinfo .info h2 {
        font-size: 25px;
        line-height: 50px; }
      .HowWeWork .companyinfo .info p {
        font-size: 16px; } }

@media screen and (max-width: 1024px) and (min-width: 768px) {
  .HowWeWork .companyinfo {
    text-align: center;
    padding-bottom: 40px; }
    .HowWeWork .companyinfo svg {
      float: unset;
      margin-bottom: 10px; }
    .HowWeWork .companyinfo .info {
      left: unset; }
      .HowWeWork .companyinfo .info h2 {
        font-size: 60px;
        line-height: 50px;
        padding-bottom: 10px; }
      .HowWeWork .companyinfo .info p {
        font-size: 23px; } }

.about_Section1 h1 {
  margin: 0; }

.about_Section1 p {
  padding-top: 0;
  padding-bottom: 52px; }

.about_Section1 img {
  margin-bottom: 30px; }

@media screen and (max-width: 767px) and (min-width: 320px) {
  .about_Section1 .imageG {
    width: 100%; } }

.singlePage .headText h3 {
  font-size: 23px;
  color: #DA231B;
  line-height: 28px;
  margin: 0;
  padding-bottom: 12px; }

.singlePage .headText p {
  margin: 0;
  padding-bottom: 26px; }

.singlePage .wrapp_news .slide img {
  margin: 0; }

.singlePage .wrapp_news .slide .content {
  margin: 0;
  padding-top: 21px;
  padding-bottom: 0; }
  .singlePage .wrapp_news .slide .content .date {
    display: none; }
  .singlePage .wrapp_news .slide .content .text {
    margin: 0; }
    .singlePage .wrapp_news .slide .content .text a h4 {
      margin-bottom: 4px;
      color: #1F1A16;
      font-weight: 600; }
    .singlePage .wrapp_news .slide .content .text h3 {
      font-size: 23px;
      line-height: 28px;
      margin: 0;
      padding-bottom: 12px;
      color: #DA231B; }
    .singlePage .wrapp_news .slide .content .text p {
      padding-bottom: 24px; }
    .singlePage .wrapp_news .slide .content .text a h6 {
      padding: 0;
      margin-bottom: 26px; }

.singlePage .wrapp_news p {
  padding-bottom: 30px; }
  .singlePage .wrapp_news p hr {
    height: 1px; }

@media screen and (max-width: 1024px) and (min-width: 768px) {
  .no-pa {
    padding: 0 15px 15px 0 !important; }
  .singlePage .wrapp_news .slide {
    margin-top: 20px; }
    .singlePage .wrapp_news .slide .content .text p {
      height: 100%; }
  .singlePage .wrapp_news p hr {
    display: none; } }

@media screen and (max-width: 1366px) and (min-width: 1024px) {
  .singlePage .detailImage {
    width: 100%; } }

.slide .content {
  margin-top: 40px;
  display: flex; }
  .slide .content .date {
    border-radius: 10px;
    color: #FFFFFF;
    text-align: center;
    float: left;
    background-color: #DA231B;
    max-height: 140px;
    min-width: 140px; }
    .slide .content .date h2 {
      font-size: 64px;
      font-weight: 600; }
      .slide .content .date h2::after {
        content: '';
        display: block;
        position: relative;
        width: 120px;
        height: 1px;
        background-color: #FFFFFF;
        left: 6px; }
    .slide .content .date p {
      font-size: 23px;
      font-weight: 600; }
  .slide .content .text {
    margin-left: 40px; }
    .slide .content .text a {
      color: #1F1A16;
      text-decoration: none; }
      .slide .content .text a h6 {
        padding-top: 24px;
        font-size: 23px;
        font-weight: 400;
        line-height: 28px;
        padding-bottom: 94px; }
        .slide .content .text a h6 .btn {
          margin-right: 8px;
          text-align: center;
          background: url("./images/arrowRight.svg") center no-repeat;
          position: relative;
          text-decoration: none;
          width: 32px;
          height: 32px;
          border-radius: 100%;
          background-color: #1F1A16; }

@media screen and (max-width: 767px) and (min-width: 320px) {
  .slide .content .text {
    margin-left: 15px; }
    .slide .content .text p {
      height: 50px;
      overflow: hidden; }
    .slide .content .text a h6 {
      padding-top: 5px !important;
      padding-bottom: 40px; } }

@media screen and (max-width: 1024px) and (min-width: 768px) {
  .slide .content .text {
    margin-left: 15px; }
    .slide .content .text p {
      height: 55px;
      overflow: hidden; }
    .slide .content .text a h6 {
      padding-top: 5px;
      padding-bottom: 40px; } }

.ourServices .box {
  margin-bottom: 20px;
  border: 12px solid #F6F7F9;
  max-width: 350px;
  height: 120px;
  display: flex; }
  .ourServices .box .content {
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 35px; }
    .ourServices .box .content svg {
      float: left; }
    .ourServices .box .content .heding {
      text-align: start; }
      .ourServices .box .content .heding h3 {
        font-size: 24px;
        font-weight: 600;
        color: #1F1A16; }

.ourServices .activ {
  border: 12px solid #DA231B; }

.ourServices p {
  margin: 0;
  padding-bottom: 30px; }

.ourServices .dot {
  max-width: 255px;
  position: relative !important;
  margin-bottom: 35px; }
  .ourServices .dot .dots {
    position: absolute;
    bottom: -28px;
    right: -28px;
    z-index: -1; }

.contact input {
  border: 1px solid #D1D1D1;
  height: 44px;
  border-radius: 5px; }

.contact input::placeholder {
  color: #232628; }

.contact label {
  text-transform: uppercase;
  font-size: 12px;
  color: #9F9F9F;
  padding-top: 10px; }

.contact textarea {
  height: 160px !important; }

.contact .btn {
  width: 140px;
  height: 40px;
  font-size: 18px;
  font-weight: 600;
  padding: 0; }

.contact .contancInfo {
  background-color: #1F1A16;
  height: 460px;
  border-radius: 5px; }
  .contact .contancInfo .content {
    color: white;
    padding: 30px 0 0 40px; }
    .contact .contancInfo .content h2 {
      font-size: 22px;
      font-weight: 600;
      line-height: 20px;
      padding-bottom: 40px;
      margin: 0; }
    .contact .contancInfo .content h3 {
      font-size: 17px;
      font-weight: 400;
      line-height: 20px;
      margin: 0; }
      .contact .contancInfo .content h3 span {
        font-weight: 700; }
    .contact .contancInfo .content h4 {
      padding-top: 20px;
      font-size: 22px;
      font-weight: 600;
      line-height: 20px;
      padding-bottom: 13px;
      margin: 0; }
    .contact .contancInfo .content p {
      font-size: 17px;
      margin: 0; }
    .contact .contancInfo .content .contantDetail {
      padding-top: 23px; }
      .contact .contancInfo .content .contantDetail h3 {
        padding-bottom: 16px; }
        .contact .contancInfo .content .contantDetail h3 svg {
          margin-right: 10px; }

@media screen and (max-width: 767px) and (min-width: 320px) {
  .contact .btn {
    margin-bottom: 20px; } }

.location .container {
  position: relative; }
  .location .container .heading {
    margin-top: 88px; }
    .location .container .heading .pp {
      margin-top: 18px;
      margin-left: -30px; }
  .location .container .mapa {
    padding-top: 42px; }
    .location .container .mapa .onMap {
      background-color: white;
      min-height: 320px;
      width: 240px;
      position: absolute;
      right: 40px;
      top: 211px;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1); }
      .location .container .mapa .onMap p {
        font-size: 17px;
        padding: 15px 0 0 19px; }
    .location .container .mapa .map {
      height: 500px; }

@media screen and (max-width: 767px) and (min-width: 320px) {
  .location .container .heading {
    margin-top: 40px; }
    .location .container .heading .pp {
      margin-left: unset; }
  .location .mapa .onMap {
    display: none; } }

@media screen and (max-width: 1024px) and (min-width: 768px) {
  .location .container .heading .pp {
    margin-left: unset; }
  .location .container .mapa .onMap {
    top: 300px; } }

@media screen and (max-width: 1366px) and (min-width: 1024px) {
  .location .container .heading .pp {
    margin-left: unset; } }

.footer {
  margin-top: 123px;
  background: url("./images/footer.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: bottom left;
  height: 497px; }
  .footer .back {
    position: absolute;
    left: 0; }
  .footer .aa {
    padding-top: 37px; }
  .footer .content {
    padding-top: 77px; }
    .footer .content .logoFoter {
      padding-bottom: 45px; }
    .footer .content p {
      font-size: 20px;
      color: white; }
    .footer .content .nav-item {
      list-style: none;
      margin-right: 42px; }
      .footer .content .nav-item a {
        font-size: 17px;
        text-transform: uppercase;
        line-height: 20px;
        font-weight: 600;
        color: #1F1A16; }
    .footer .content .phone svg {
      float: left; }
    .footer .content .phone h4 {
      font-size: 20px;
      line-height: 24px; }
    .footer .content .phone p {
      color: #1F1A16; }
    .footer .content .time svg {
      float: left; }
    .footer .content .time h4 {
      font-size: 20px;
      line-height: 24px; }
    .footer .content .time p {
      color: #1F1A16; }
  .footer .copyright p {
    padding-top: 91px;
    font-size: 20px;
    color: #FFFFFF; }
  .footer .copyright .bla p {
    color: #1F1A16; }
    .footer .copyright .bla p a {
      color: #1F1A16;
      text-decoration: none; }

@media screen and (max-width: 767px) and (min-width: 320px) {
  .footer {
    background-image: none;
    background-color: #DA231B;
    height: auto; }
    .footer .content {
      color: white; }
    .footer .copyright p {
      padding: 0;
      color: white; }
    .footer .copyright .bla p {
      padding-bottom: 20px;
      padding-top: 20px;
      color: white; }
      .footer .copyright .bla p a {
        color: white; } }

@media screen and (max-width: 1024px) and (min-width: 768px) {
  .footer {
    margin: 0;
    height: auto; } }

html,
body {
  font-family: 'Barlow', sans-serif !important;
  overflow-x: hidden !important; }
  html h1,
  body h1 {
    font-weight: 400;
    font-size: 50px;
    line-height: 60px; }
    html h1 span,
    body h1 span {
      font-weight: 800; }
    html h1::after,
    body h1::after {
      content: '';
      display: block;
      position: relative;
      background-color: #DA231B;
      height: 4px;
      width: 100px;
      margin-top: 10px;
      margin-bottom: 26px; }
  html h4,
  body h4 {
    font-size: 30px;
    line-height: 36px; }
  html p,
  body p {
    margin: 0;
    font-size: 23px;
    line-height: 28px;
    font-weight: 400; }
    html p span,
    body p span {
      font-weight: 700; }
  html .btn,
  body .btn {
    color: #FFFFFF;
    font-weight: 500;
    border-radius: 10px;
    background-color: #DA231B; }

.no-ma-right {
  margin-right: 0 !important; }

.no-pa {
  padding: 0; }

.header {
  margin-top: 99px;
  margin-bottom: 45px; }
  .header svg {
    margin-right: 30px; }
  .header h3 {
    font-size: 42px;
    color: #1F1A16;
    line-height: 50px;
    font-weight: 400; }
    .header h3 span {
      font-weight: 700; }
  .header p {
    padding-top: 49px;
    padding-bottom: 38px; }
  .header .btn {
    width: 290px;
    height: 60px;
    font-size: 25px; }

@media screen and (max-width: 1024px) and (min-width: 768px) {
  .header {
    margin-top: 50px; } }

.button .btn {
  background: url("./images/mouseScroll.svg") center;
  background-size: contain;
  width: 24px;
  height: 62px;
  border: 0;
  position: relative;
  text-decoration: none; }
  .button .btn:hover {
    background-color: white !important;
    border: 0 !important; }

.whatWeDo {
  margin-top: 77px;
  text-align: center; }
  .whatWeDo h4 {
    color: #DA231B;
    font-weight: 600; }
  .whatWeDo h1 {
    text-align: center;
    font-weight: 400; }
    .whatWeDo h1::after {
      left: 47.5%; }
  .whatWeDo p {
    margin-bottom: 64px; }
  .whatWeDo .box {
    margin-bottom: 40px;
    border: 16px solid #F6F7F9;
    height: 320px;
    display: flex; }
    .whatWeDo .box .content {
      display: block;
      margin-top: auto;
      margin-bottom: auto;
      width: 100%; }
      .whatWeDo .box .content h4 {
        color: #1F1A16; }
  .whatWeDo .color {
    border: 0;
    background-color: #DA231B; }
    .whatWeDo .color .content a {
      text-decoration: none !important; }
      .whatWeDo .color .content a h4 {
        color: #FFFFFF; }
        .whatWeDo .color .content a h4::after {
          content: '';
          border: solid #ffffff;
          border-width: 0 4px 4px 0;
          display: inline-block;
          padding: 8px;
          transform: rotate(-45deg);
          -webkit-transform: rotate(-45deg);
          position: relative;
          left: 4.3px; }

.gallery .container .heading {
  margin-top: 90px; }
  .gallery .container .heading h1 {
    padding-bottom: 26px; }
  .gallery .container .heading p {
    float: left;
    margin: 0;
    padding-top: 20px; }
  .gallery .container .heading .btn {
    width: 250px;
    height: 50px;
    font-size: 20px;
    float: right; }

.gallery .container .galerija {
  padding-top: 104px; }
  .gallery .container .galerija .slika {
    margin-bottom: 30px !important; }

@media screen and (max-width: 767px) and (min-width: 320px) {
  .gallery .container .heading {
    margin-top: 40px; }
    .gallery .container .heading h1 {
      padding-bottom: unset; }
    .gallery .container .heading p {
      padding-top: unset; }
    .gallery .container .heading .btn {
      float: left;
      margin-top: 15px;
      width: 210px;
      height: 40px;
      font-size: 16px; }
  .gallery .container .galerija .slika {
    width: 100% !important; } }

.news {
  margin-top: 55px;
  background-color: #F6F7F9; }
  .news h1 {
    padding-top: 80px; }
    .news h1::after {
      content: '';
      display: block;
      position: relative;
      background-color: #DA231B;
      height: 4px;
      width: 100px;
      margin-top: 10px;
      margin-bottom: 26px; }
  .news .slick-slider {
    width: 100%;
    position: relative;
    padding-top: 52px; }
    .news .slick-slider .slick-list {
      padding: 3px; }
    .news .slick-slider .slick-arrow:hover {
      background-color: #DA231B;
      height: 46px;
      width: 46px; }
      .news .slick-slider .slick-arrow:hover svg {
        fill: white; }
    .news .slick-slider .slick-next {
      position: absolute;
      right: 15px;
      top: 0;
      background: #fff;
      color: transparent;
      height: 40px;
      width: 40px;
      z-index: 1;
      border: 0; }
      .news .slick-slider .slick-next:before {
        content: '' !important;
        border: solid #111111;
        border-width: 0 2px 2px 0;
        display: inline-block;
        padding: 5px;
        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
        position: relative; }
    .news .slick-slider .slick-prev {
      position: absolute;
      right: 65px;
      top: 0;
      left: unset;
      background-color: #fff;
      color: transparent;
      height: 40px;
      width: 40px;
      z-index: 1;
      border: 0; }
      .news .slick-slider .slick-prev:before {
        content: '' !important;
        border: solid #111111;
        border-width: 0 2px 2px 0;
        display: inline-block;
        padding: 5px;
        transform: rotate(135deg);
        -webkit-transform: rotate(135deg);
        position: relative; }
    .news .slick-slider .slide img {
      max-height: 340px;
      object-fit: cover;
      width: 97%; }
    .news .slick-slider .slide .content {
      margin-top: 40px;
      display: flex; }
      .news .slick-slider .slide .content .date {
        border-radius: 10px;
        color: #FFFFFF;
        text-align: center;
        float: left;
        background-color: #DA231B;
        max-height: 140px;
        min-width: 140px; }
        .news .slick-slider .slide .content .date h2 {
          font-size: 64px;
          font-weight: 600; }
          .news .slick-slider .slide .content .date h2::after {
            content: '';
            display: block;
            position: relative;
            width: 120px;
            height: 1px;
            background-color: #FFFFFF;
            left: 6px; }
        .news .slick-slider .slide .content .date p {
          font-size: 23px;
          font-weight: 600; }
      .news .slick-slider .slide .content .text {
        margin-left: 40px; }
        .news .slick-slider .slide .content .text a {
          color: #1F1A16;
          text-decoration: none; }
          .news .slick-slider .slide .content .text a h6 {
            padding-top: 24px;
            font-size: 23px;
            font-weight: 400;
            line-height: 28px;
            padding-bottom: 94px; }
            .news .slick-slider .slide .content .text a h6 .btn {
              margin-right: 8px;
              text-align: center;
              background: url("./images/arrowRight.svg") center no-repeat;
              position: relative;
              text-decoration: none;
              width: 32px;
              height: 32px;
              border-radius: 100%;
              background-color: #1F1A16; }

@media screen and (max-width: 767px) and (min-width: 320px) {
  html,
  body {
    overflow-x: hidden !important; }
    html h1,
    body h1 {
      font-size: 30px;
      line-height: 45px; }
    html h4,
    body h4 {
      font-size: 25px;
      line-height: 30px; }
    html p,
    body p {
      font-size: 18px;
      line-height: 24px; }
  .header {
    margin-top: 90px; }
    .header svg {
      display: none; }
    .header h3 {
      padding-top: 30px;
      font-size: 37px;
      line-height: 40px; }
    .header p {
      padding-top: 20px; }
    .header .btn {
      width: 210px;
      height: 40px;
      font-size: 18px; }
  .whatWeDo {
    margin-top: 50px; }
    .whatWeDo h1::after {
      left: 37.5%; }
    .whatWeDo h4 {
      margin-top: 30px; }
    .whatWeDo .no-pa-up {
      margin-top: 0; }
  .news h1 {
    padding-top: 40px; }
  .news .slick-slider {
    padding-top: 40px; }
    .news .slick-slider .slick-arrow:hover {
      height: 39px;
      width: 39px; }
    .news .slick-slider .slick-next {
      top: -10px;
      height: 35px;
      width: 35px;
      right: 0; }
    .news .slick-slider .slick-prev {
      right: 37px;
      top: -10px;
      height: 35px;
      width: 35px; }
    .news .slick-slider .slide .content .date h2 {
      padding-top: 12px;
      font-size: 54px; }
    .news .slick-slider .slide .content .date p {
      font-size: 18px; }
    .news .slick-slider .slide .content .text {
      margin-left: 10px; }
      .news .slick-slider .slide .content .text a h6 {
        padding-top: 10px;
        padding-bottom: 20px; }
      .news .slick-slider .slide .content .text p {
        height: 50px;
        overflow: hidden; } }

@media screen and (max-width: 1024px) and (min-width: 768px) {
  .whatWeDo h1::after {
    left: 44.5%; }
  .news .slick-slider .slick-arrow:hover {
    height: 39px;
    width: 39px; }
  .news .slick-slider .slick-next {
    top: -10px;
    height: 35px;
    width: 35px; }
  .news .slick-slider .slick-prev {
    right: 58px;
    top: -10px;
    height: 35px;
    width: 35px; }
  .news .slick-slider .slide .content .text {
    margin-left: 10px; }
    .news .slick-slider .slide .content .text a h6 {
      padding-top: 10px; }
    .news .slick-slider .slide .content .text p {
      height: 55px;
      overflow: hidden; }
  .HowWeWork .content .image {
    width: 100%;
    padding-bottom: 20px; }
  .HowWeWork .companyinfo {
    padding-top: 40px; } }
